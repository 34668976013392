import request from '@/utils/request'

export function get(id) {
  return request({
    url: "api/costBill/" + id,
    method: "get"
  })
}

export function add(data) {
  return request({
    url: 'api/costBill',
    method: 'post',
    data
  })
}

export function edit(data) {
  return request({
    url: 'api/costBill',
    method: 'put',
    data
  })
}

export function del(id) {
  return request({
    url: 'api/costBill/' + id,
    method: 'delete'
  })
}

export function submit(id) {
  return request({
    url: 'api/costBill/submit/' + id,
    method: "post"
  })
}

export function cancel(id) {
  return request({
    url: 'api/costBill/cancel/' + id,
    method: 'delete'
  })
}

export function pay(data) {
  return request({
    url: 'api/payForm/buyer',
    method: 'post',
    data
  })
}