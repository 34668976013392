<template>
  <div>
    <form ref="onlineUnionForm" :action="payData.url" target="_online_pay" method="POST" v-if="payData">
      <input type="hidden" v-for="(v, k) in payData.params" :key="k" :name="k" :value="v" />
    </form>

    <el-form :model="payForm" label-position="right" label-width="84px" label-suffix="：" v-if="payable">
      <el-form-item label="实付金额">
        <div class="h c">
          <b class="fs-large fc-e">{{$price(payMoney * (1 + feeRate))}}</b>
          <span class="fc-g padding-0-10">支付金额：{{$price(payMoney)}} + 手续费用：{{$price(payMoney * feeRate)}}</span>
        </div>
      </el-form-item>
      <el-form-item label="使用余额" v-if="useRemainable">
        <div class="h c">
          <price-input v-model="payForm.accountMoney" :min="0" :max="maxRemain / 100" style="width: 200px;" />
          <div class="padding-0-10 fc-g">可使用余额：{{$price(maxRemain)}}</div>
        </div>
      </el-form-item>
      <el-form-item label="支付方式" v-if="showPayChannel && (money > 0 && money > payForm.accountMoney)">
        <div class="h c">
          <el-select v-model="payForm.payChannel" :clearable="false" style="width: 200px;">
            <el-option value="thirdPay" label="使用第三方支付进行支付" />
            <el-option value="unionPay" label="使用银联进行支付" />
          </el-select>
          <div class="padding-0-10 fc-g">{{payForm.payChannel === 'thirdPay' ? '请使用微信或支付宝扫描打开的支付页面中的二维码进行支付' : '请按照打开的支付页面引导流程进行支付'}}</div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="busy" @click="pay(null)">立即支付</el-button>
      </el-form-item>
    </el-form>

    <el-dialog :visible.sync="dialog" title="系统提示" append-to-body :close-on-click-modal="false" width="640px" @closed="message = null;">
      <div>{{bill && bill.payChannel === 'thirdPay' ? '请使用微信或支付宝扫描打开的支付页面中的二维码进行支付。' : '请按照打开的支付页面引导流程进行支付'}}</div>
      <div class="fc-e" v-if="message" style="margin-top: 6px;">温馨提示：{{message}}</div>
      <div style="margin-top: 20px;">
        <el-button type="primary" :loading="checking" @click="handleCheckPay">已经完成支付</el-button>
        <el-button @click="handleQA">支付遇到了问题</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import { getBySupplier } from "@/api/capitalPool";

export default {
  props: {
    money: Number,
    useRemainable: {
      type: Boolean,
      default: true,
    },
    supplier: {
      type: Number | String,
      required: true,
    },
    type: String,
    orderFormId: String | Number,
  },
  data() {
    return {
      maxRemain: 0,
      busy: false,
      feeRate: 0,
      payForm: {
        payMoney: this.money,
        accountMoney: 0,
        payChannel: "thirdPay",
      },
      bill: null,
      payData: null,
      dialog: false,
      message: "",
      checking: false,
      showPayChannel: false,
    };
  },
  computed: {
    payable() {
      return (
        typeof this.money === "number" && !isNaN(this.money) && this.money > 0
      );
    },
    payMoney() {
      return (this.money || 0) - (this.payForm.accountMoney || 0);
    },
  },
  methods: {
    loadMaxRemainMoney() {
      if (this.supplier && this.useRemainable) {
        getBySupplier(this.supplier).then((res) => {
          let max = res.canUseBalance;
          if (max < 0) max = 0;
          if (max > this.money) max = this.money;
          this.maxRemain = max;
          this.payForm.accountMoney = this.maxRemain;
        });
      }
    },
    loadFeeRate() {
      if (this.supplier) {
        request({
          url: "api/pay/rate",
          method: "get",
          params: {
            payMoney: 10000,
            entId: this.supplier,
          },
        }).then((res) => {
          this.feeRate = res.feeRate * res.feeDistributorRate;
        });
      } else {
        this.feeRate = 0;
      }
    },
    checkSellerErp() {
      if (this.supplier) {
        request({
          url: "api/check/erp",
          method: "get",
          params: {
            entId: this.supplier,
          },
        }).then((res) => {
          this.showPayChannel = res;
        });
      }
    },
    pay(bill) {
      if (this.busy) return false;
      if (!this.showPayChannel && this.payForm.accountMoney < this.money) {
        this.$notify({
          title: "付款金额不能小于订单金额",
          type: "warning",
          duration: 2500,
        });
        return false;
      }
      if (this.type === "purchase") {
        this.handlePurchasePay(bill);
      } else if (this.type === "cost") {
        this.handleCostPay(bill);
      } else if (this.type === "balance" && bill) {
        this.handleBalancePay(bill);
      } else if (this.type === "deposit") {
        this.handleDepositPay(bill);
      }
    },
    // 采购付款
    handlePurchasePay(bill) {
      let form = bill || {
        payMoney: this.money - this.payForm.accountMoney,
        relationFormId: this.orderFormId,
        payChannel: this.payForm.payChannel,
        accountMoney: this.payForm.accountMoney,
        info: this.info,
      };
      if (form.payMoney >= 0) {
        this.bill = form;
        this.busy = true;
        request({
          url: "api/orderForm/payOnLine",
          method: "post",
          data: form,
        })
          .then(this.handleOnlinePaid)
          .finally((_) => {
            this.busy = false;
          });
      } else {
        this.$message.error("实付金额不可小于0");
      }
    },
    // 费用单付款
    handleCostPay(bill) {
      let form = bill || {
        payMoney: this.money - this.payForm.accountMoney,
        relationFormId: this.orderFormId,
        payChannel: this.payForm.payChannel,
        accountMoney: this.payForm.accountMoney,
        info: this.info,
      };
      if (form.payMoney >= 0) {
        this.bill = form;
        this.busy = true;
        request({
          url: "api/costBill/payOnline",
          method: "post",
          data: form,
        })
          .then(this.handleOnlinePaid)
          .finally((_) => {
            this.busy = false;
          });
      } else {
        this.$message.error("实付金额不可小于0");
      }
    },
    // 余额充值
    handleBalancePay(bill) {
      this.bill = bill;
      this.busy = true;
      request({
        url: "api/payForm/cashIn/payOnLine",
        method: "post",
        data: bill,
      })
        .then(this.handleOnlinePaid)
        .finally((_) => {
          this.busy = false;
        });
    },
    // 保证金付款
    handleDepositPay(bill) {
      let form = bill || {
        // ...
        payMoney: this.money,
        relationFormId: this.orderFormId,
        payChannel: this.payForm.payChannel,
      };
      this.bill = form;
      this.busy = true;
      request({
        url: "api/deposit/payOnline",
        method: "post",
        data: form,
      })
        .then(this.handleOnlinePaid)
        .finally((_) => {
          this.busy = false;
        });
    },
    handleOnlinePaid(res) {
      if (res) {
        if (res.finish) {
          this.$notify({
            title: "支付成功",
            type: "success",
            duration: 2500,
          });
          this.$emit("paied", true);
        } else {
          this.payData = res;
          if (res.type === "thirdPay") {
            let a = document.createElement("a");
            a.href = res.url;
            a.target = "_online_pay";
            a.click();
          } else {
            this.$nextTick((_) => {
              this.$refs.onlineUnionForm && this.$refs.onlineUnionForm.submit();
            });
          }
          this.$emit("paied");
          this.dialog = true;
        }
      } else {
        this.$alert("请求支付失败，请稍候重试。", "支付提示", {
          type: "warning",
        });
      }
    },
    handleCheckPay() {
      if (!this.checking && this.payData) {
        this.checking = true;
        request({
          url: "api/pay/query",
          method: "get",
          params: {
            orderNo: this.payData.orderNo,
          },
        })
          .then((res) => {
            if (res === "paid") {
              this.dialog = false;
              this.message = "";
              this.$emit("paied", true);
            } else {
              this.message =
                res === "none"
                  ? "还没有进行支付或者还没有到账。"
                  : "支付发生异常，请稍候重试。";
            }
          })
          .finally((_) => {
            this.checking = false;
          });
      }
    },
    handlePayMoneyChange() {
      if (this.balance) {
        this.$emit("update:money", this.payForm.payMoney);
      }
    },
    handleQA() {
      this.dialog = false;
    },
  },
  mounted() {
    this.loadMaxRemainMoney();
    this.loadFeeRate();
    this.checkSellerErp();
  },
};
</script>